import './App.css';
import {useEffect, useState} from "react";
import ReactGA from "react-ga";
import MainPage from "./pages/MainPage";
import MapProductPage from "./pages/MapProductPage";
import WhatsAppLink from './components/WhatsAppLink'
import { UAParser } from 'ua-parser-js';

import {
  BrowserRouter as Router, Route, Routes,
} from 'react-router-dom';
import Navbar from "./components/Navbar";
const baseURL = process.env.REACT_APP_BASE_URL;
function App() {

  useEffect(() => {
    ReactGA.initialize("G-TZC5G8SHYH");
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {

    const parser = new UAParser();
    const browser = parser.getBrowser().name; // Browser name (e.g., Chrome, Firefox, Safari)
    const os = parser.getOS().name; // OS name (e.g., Windows, macOS, Linux)

    const trackImpression = () => {
      const pageUrl = window.location.pathname;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          pageUrl,
          os,
          browser,
        }),
      };

      fetch(`${baseURL}/v1/wi/impression`, requestOptions)
        .then(response => response.json())
        .then(() => {
          console.info('im')
        });
    }
    trackImpression();
  }, []);

  return (
    <Router>
      <Navbar />
      <WhatsAppLink />
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route path="/regulations" element={<MainPage />} />
        <Route path="/producta" element={<MapProductPage productIndex={'A'} />} />
        <Route path="/productb" element={<MapProductPage productIndex={'B'} />} />
        <Route path="/mo" element={<MapProductPage productIndex={'C'} />} />
      </Routes>
    </Router>
  );
}

export default App;
